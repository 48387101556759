import React from "react"
import { HashRouter,Route,Switch } from "react-router-dom"
import Login from '../views/login/Login';
import Register from "../views/register/index"
import AuthRouter from '@/views/auth/AuthRouter';
import Layout from "@/views/layout/index"

const router =()=>{
    return (
        <HashRouter>
            <Switch>
                <Route component={Login} exact path="/login"></Route>
                <Route component={Register} exact path="/register"></Route>
                <AuthRouter path="/" Component={Layout} />
            </Switch>
        </HashRouter>
    )
}

export default router;
