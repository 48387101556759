import React, { Component } from 'react'

export default class MarkDown extends Component {
    render() {
        return (
            <div>
                markDown
            </div>
        )
    }
}
