
import React, { Component } from 'react'
import { Modal,Form,Col,Table,Tag  } from 'antd';
 import {findSpotCheckListById } from "./service"

export class Detail extends Component {

    state = { visible: false,tableData:[] };

    constructor(props){
       super(props)
       console.log("组件详情")
      //  console.log(this.props)
    }


    showModal = () => {
      this.setState({
        visible: true,
      });
    };
  
    handleOk = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
  
    handleCancel = e => {
      console.log(e);
      this.props.close(true)
    };
    componentDidMount(){
       console.log("哈哈")
    }
    //props参数变化调用  props当前prop变化 this.props上一个props
    componentWillReceiveProps(props){
      //  console.log(props)
      if(props.visible===true){
         findSpotCheckListById({
            id:props.detail.id
         },(res)=>{
            console.log(res)
            if(res){
               // this.setState({
               //    tableData:res.data
               // })

               this.setState({
                  tableData:res.data
               })
            }
            
         })
      } 
    }

    render() {
        const columns=[
            {
                dataIndex:"checkItem",
                title:"检查项目",
                align:"center",
            },
            {
               
                title:"检查结论",
                align:"center",
                render:(h)=>{
                   
                   if(h.result==="true"){
                       return  <Tag color="blue">正常</Tag>
                    }else{
                       return <Tag  color="red">异常</Tag>
                    }
                  
                } 

            }
        ]
     


        return (
            <>
            <Modal
              title="点检详情"
              visible={this.props.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width="800px"
              footer={null}
              maskClosable={false}
            >
          <Form  layout='inline'  labelCol={{span: 10,offset:0}}  wrapperCol={{ span: 16 }}>
             <Col span={8}>
                <Form.Item label="车辆牌号">
                     {this.props.detail.vehicleNo}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="驾驶员">
                   {this.props.detail.userName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="联系电话">
                {this.props.detail.phone}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="使用单位">
                {this.props.detail.companyName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="点检时间">
                {this.props.detail.createDate}
                </Form.Item>
             </Col>


          </Form>

               <Table style={{marginTop:"10px"}}  rowKey={record => record.id}  columns={columns} dataSource={this.state.tableData} pagination={null}></Table>
            </Modal>
          </>
        )
    }
}

export default Detail
