import { baseUrl } from  "@/enviroment/index"

// 获取blob对象
function getBlob (url, cb, data) {
  console.log(url)
  var xhr = new XMLHttpRequest()
  xhr.open('post', url, true)
  xhr.setRequestHeader('Content-Type', ' application/json')
  xhr.responseType = 'blob'
  xhr.onload = function () {
    console.log('请求成功')
    if (xhr.status === 200) {
      console.log(xhr.response)
      cb(xhr.response)
    }
  }
  xhr.send(data)
}
// 保存
function saveAs (blob, filename) {
  let link = document.createElement('a')
  let body = document.querySelector('body')
  link.href = window.URL.createObjectURL(blob)
  link.download = filename
  link.style.display = 'none'
  body.appendChild(link)
  link.click()
  body.removeChild(link)
}

function download (url, fileName, data,fun) {
  getBlob(baseUrl + url, (blob) => {
    fun()
    saveAs(blob, fileName)
  }, JSON.stringify(data))
}

export default download
