
import React, { Component } from 'react'
import { Modal,Form,Col,Tabs,Input,Row,Space,DatePicker,Select,Button,Upload,message } from 'antd';
import { FolderAddTwoTone} from "@ant-design/icons"
import { insertForklift } from "../service"
import { baseUrl } from  "@/enviroment/index"
import moment from 'moment'
const { TabPane } = Tabs;
const { Option } = Select;

const { Dragger } = Upload;




export class InsertForklift extends Component {

    state = { visible: false,confirmLoading:false,layout:{} }

    tabIndex=1

    formRef = React.createRef();


   uploadProps={
    name: 'file',
    multiple: false,
    action: baseUrl+'/api/sysForklift/batchInsertForklift',
    onChange:(info)=>{
      const { status } = info.file;
      let hide

      if (status !== 'uploading') {
         hide= message.loading('正在导入数据...', 0);
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
         hide()
         console.log("done")
         console.log(info)
         const res=info.file.response
         console.log(res.status)
         if(res.status===200||res.status==="200"){

            setTimeout(()=>{
               message.success(res.message)
               this.props.close(true)
            },1000)
         }else{
             message.error(res.message)
         }

      } else if (status === 'error') {
         hide()
      }
    },
   }

    constructor(props){
       super(props)
       console.log("组件详情")
    }
    //
    handleComfirm(){

      if(this.tabIndex===1){

         this.setState({
            confirmLoading:true
          })
          let formValues=this.formRef.current.getFieldsValue()


      let userInfo=JSON.parse(localStorage.getItem("userInfo"))
      console.log(userInfo)

      insertForklift({
            licenseNo:formValues.licenseNo||"",
            brandModel:formValues.brandModel||"",
            vehicleNo:formValues.vehicleNo||"",
            engineNumber:formValues.engineNumber||"",
            factoryNumber:formValues.factoryNumber||"",
            factoryDate:formValues.factoryDate?moment(formValues.factoryDate).format('YYYY-MM-DD'):"",
            powerWay:formValues.powerWay||"",
            checkoutDate:formValues.checkoutDate?moment(formValues.checkoutDate).format('YYYY-MM-DD'):"",
            dueDate:formValues.dueDate?moment(formValues.dueDate).format('YYYY-MM-DD'):"",
            produceCompany:formValues.produceCompany||"",
            companyId:userInfo.id,
            registerCode:formValues.registerCode||"",
         },(res)=>{
            this.setState({
               confirmLoading:false
             })
            if(res){
               message.success(res.message)
               this.props.close(true)
            }
         })

      }

    }

   //tab变化
    tabChange(value){
       console.log(value)
       this.tabIndex=value


    }


    handleCancel = e => {
      console.log(e);
      this.props.close(true)
    };
    //props参数变化调用  props当前prop变化 this.props上一个props
    componentWillReceiveProps(props){
        console.log(JSON.parse(localStorage.getItem("userInfo")))
    }

    render() {
        return (
            <>
            <Modal
              title="新增叉车台账"
              visible={this.props.visible}
              destroyOnClose={true}
              onCancel={this.handleCancel}
            //   onOk={this.handleComfirm.bind(this)}
              width="900px"
              confirmLoading={this.state.confirmLoading}
              footer={null}
              maskClosable={false}


            >

        <Tabs defaultActiveKey="1"  onChange={this.tabChange.bind(this)} style={{marginTop:'-12px'}}>
            <TabPane tab="手动添加" key="1">
            <Form  layout='inline'  onFinish={this.handleComfirm.bind(this)}  ref={this.formRef} labelCol={{span: 10,offset:0}}  wrapperCol={{ span: 16 }}>
            <Space direction="vertical" size="large">
            <Row  gutter={20}>
             <Col span={8}>
                <Form.Item label="使用证号" name="licenseNo" rules={[{ required: true, message: '请输入使用证号' }]} hasFeedback>
                     <Input placeholder="请输入使用证号"></Input>
                </Form.Item>
             </Col>


             <Col span={8}>
                <Form.Item label="车辆牌号" name="vehicleNo" rules={[{ required: true, message: '请输入车辆牌号' }]} hasFeedback>
                   <Input placeholder="请输入车辆牌号"></Input>
                </Form.Item>
             </Col>

             <Col span={8}>
                <Form.Item label="注册代码" name="registerCode" rules={[{ required: true, message: '请输入注册代码' }]} hasFeedback>
                   <Input placeholder="请输入注册代码"></Input>
                </Form.Item>
             </Col>

             </Row>
             <Row  gutter={20}>
             <Col span={8} >
                <Form.Item label="发动机编号" name="engineNumber" rules={[{ required: false, message: '请输入发动机编号' }]} hasFeedback>
                   <Input placeholder="请输入发动机编号"></Input>
                </Form.Item>
             </Col>

             <Col span={8} >
                <Form.Item label="检验日期" name="checkoutDate" rules={[{ required: true, message: '请选择检验日期' }]} hasFeedback>
                  <DatePicker  placeholder="请选择检验日期" />
                </Form.Item>
             </Col>

             <Col span={8} >
                <Form.Item label="到期日期" name="dueDate" rules={[{ required: true, message: '请选择到期日期' }]} hasFeedback>
                    <DatePicker  placeholder="请选择到期日期" />
                </Form.Item>
             </Col>


          </Row>
          <Row  gutter={20}>
             <Col span={8} >
                <Form.Item label="叉车类型" name="powerWay" rules={[{ required: false, message: '请选择动力方式' }]} hasFeedback>
                   <Select placeholder="请选择叉车类型" allowClear>
                       <Option value="内燃机">内燃机</Option>
                        <Option value="电动机">电动机</Option>
                   </Select>
                </Form.Item>
             </Col>

             <Col span={8} >
                <Form.Item label="出厂编号" name="factoryNumber" rules={[{ required: false, message: '请输入出厂编号' }]} hasFeedback>
                   <Input placeholder="请输入出厂编号"></Input>
                </Form.Item>
             </Col>

             <Col span={8} >
                <Form.Item label="出厂日期" name="factoryDate" rules={[{ required: false, message: '请输入出厂日期' }]} hasFeedback>
                       <DatePicker  placeholder="请输入出厂日期" />
                </Form.Item>
             </Col>

          </Row>
          <Row  gutter={20}>
             <Col span={8} >
               <Form.Item label="制造单位" name="produceCompany" rules={[{ required: false, message: '请输入制造单位名称' }]} hasFeedback>
                   <Input placeholder="请输入制造单位名称"></Input>
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="厂牌型号" name="brandModel" rules={[{ required: false, message: '请输入厂牌型号' }]} hasFeedback>
                   <Input placeholder="请输入厂牌型号"></Input>
                </Form.Item>
             </Col>
          </Row>

          <Row style={{marginTop:'20px'}}>

            <Col span={20} >

            </Col>
            <Col span={4} >
               <Button type="primary" htmlType="submit">保存</Button>
            </Col>
            </Row>
            </Space>
          </Form>
        </TabPane>
        <TabPane tab="表格导入" key="2">
            <Row>
                <Col span={20}>
                    <Dragger {...this.uploadProps} style={{width:"80%"}}>
                     <p className="ant-upload-drag-icon">
                      <FolderAddTwoTone />
                     </p>
                    <p className="ant-upload-text">点击或者拖拽文件到这个区域进行上传</p>
                    <p className="ant-upload-hint">
                     请选择需要上传的叉车台账
                    </p>
                    </Dragger>
                </Col>
                <Col span={4} >
                <Button type="dashed" onClick={()=>{window.location.href='http://47.97.111.28:8080/upload/template/forklift.xls'}}>下载模板</Button>
             </Col>
            </Row>
          </TabPane>
        </Tabs>
     </Modal>
          </>
        )
    }
}

export default InsertForklift
