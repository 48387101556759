import axios from '@/utils/axios'
function dataInfo(param, fun){
    axios.post('/api/index/dataInfo', param).then((data) => {
      fun(data)
    })
}


function findOverdueList(param, fun){
  axios.post('/api/index/findOverdueList', param).then((data) => {
    fun(data)
  })
}

function censusTenForkliftDate(param, fun){
  axios.post('/api/index/censusTenForkliftDate', param).then((data) => {
    fun(data)
  })
}

export { dataInfo,findOverdueList,censusTenForkliftDate}