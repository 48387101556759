
import React, { Component } from 'react'
import 
{ 
 Modal,
 Form,
 Col,
 Image
} from 'antd';

export class Detail extends Component {

    close(){
        this.props.close()
    }

    render() {
        return (
            <>
              <Modal 
               title="详情"
               visible={this.props.visible}
               width="800px"
               footer={null}
               onCancel={this.close.bind(this)}
               maskClosable={false}
              >
            <h3 style={{fontWeight:"bold"}}>基本信息</h3>
           <Form  layout='inline'  labelCol={{span: 10,offset:0}}  wrapperCol={{ span: 16 }}>
             <Col span={8}>
                <Form.Item label="设备名称">
                     {this.props.detail.locationEquipmentName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="车牌号">
                   {this.props.detail.vehicleNo}
                </Form.Item>
             </Col>
             {/* <Col span={8}>
                <Form.Item label="安装人员">
                    {this.props.detail.locationEquipmentNum}
                </Form.Item>
             </Col> */}
             <Col span={8}>
                <Form.Item label="设备编号">
                   {this.props.detail.locationEquipmentNum}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="安装时间">
                    {this.props.detail.bindDate}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="状态">
                    {this.props.detail.stateName}
                </Form.Item>
             </Col>
             <Col span={12}>
                <Form.Item label="所属单位">
                  {this.props.detail.companyName}
                </Form.Item>
             </Col>


          </Form>
          
          <h3 style={{fontWeight:"bold",marginTop:"20px"}}>安装现场图片</h3>
              {
                 this.props.detail.forkliftLocalUrl?this.props.detail.forkliftLocalUrl.split(',').map((item,index)=>{
                     return <Image style={{"marginRight":"20px"}}  key={index} width={100} src={item} />
                 }):[]
              }

              </Modal>
            </>
        )
    }
}

export default Detail
