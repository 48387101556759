import axios from '@/utils/axios'



function applyDriverCarList(param, fun){
    axios.post('/api/sysuser/applyDriverCarList', param).then((data) => {
      fun(data)
    })
}




function checkApplyDriverCarList(param, fun){
  axios.post('/api/sysuser/checkApplyDriverCarList', param).then((data) => {
    fun(data)
  })
}






export { applyDriverCarList,checkApplyDriverCarList}