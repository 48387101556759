import axios from 'axios'
import { message } from 'antd';
import { baseUrl } from  "@/enviroment/index"

const Message=message
// 网络请求超时时间
axios.defaults.timeout = 60000
// 设置请求头
axios.defaults.headers.post['Content-Type'] = 'application/json'


// 设置baseUrl
axios.defaults.baseURL = baseUrl

// 当前正在请求的数量
let requestNum = 0

// 展示loading效果
function startLoading () {
  if (requestNum === 0) {
     
    //loading start
  }
  requestNum++
}
// 延迟请求
function endLoading () {
  setTimeout(closeLoading, 0)
}

// 关闭loading
function closeLoading () {
  if (requestNum <= 0) return
  requestNum--
  if (requestNum === 0) {
     //loading done
  }
}



axios.interceptors.request.use(config => {


  
  // 显示loading
  startLoading()
  return config
}, (errror) => {
  console.log(errror)
})

axios.interceptors.response.use((response) => {
  // 成功回调
  endLoading()

  if (response.status === 200 || response.status === '200') {
    try {
      // 请求成功
      if (response.data.status === 200 || response.data.status === '200') {
        return response.data
      } else {
        Message.error(response.data.message);
        
        return null
      }
    } catch (e) {
      Message.error('参数解析失败')
    }
  } else {
    Message.error('发生异常，请联系管理员')
    return null
  }
}, (error) => {
  endLoading()
  console.log(error)
  // 发生异常关闭loading框
  const { code, message } = error
  if (code === 'ECONNABORTED' || message.indexOf('timeout') !== -1) {
    // 网络超时，请重新连接
     Message.error('网络超时，请重新请求')
  } else {
     Message.error(`服务器异常，${message}`)
  }
})

export default axios
