import React, { Component } from 'react'

export default class MenuTwo extends Component {
    render() {
        return (
            <div>
                菜单二
            </div>
        )
    }
}
