
import React, { Component } from 'react'
import 
{ 
 Modal,
 Form,
 Col,
 Image, Row
} from 'antd';

export class Detail extends Component {

    close(){
        this.props.close()
    }

    render() {
        return (
            <>
              <Modal 
               title="详情"
               visible={this.props.visible}
               width="800px"
               footer={null}
               onCancel={this.close.bind(this)}
               maskClosable={false}
              >
            <h3 style={{fontWeight:"bold"}}>基本信息</h3>
           <Form  layout='inline'  labelCol={{span: 10,offset:0}}  wrapperCol={{ span: 16 }}>
             <Col span={8}>
                <Form.Item label="车辆牌号">
                     {this.props.detail.vehicleNo}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="交接人">
                   {this.props.detail.spotCheckMan}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="验收人">
                    {this.props.detail.handoverMan}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="使用单位">
                   {this.props.detail.companyName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="是否异常">
                    {this.props.detail.ifExceptionName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="是否修好">
                    {this.props.detail.ifUpdateName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="交接时间">
                  {this.props.detail.spotCheckEndDate}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="确认时间">
                  {this.props.detail.sureDate}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="信息是否一致">
                  {this.props.detail.isDifferenceName}
                </Form.Item>
             </Col>

          </Form>
       <Row>
         <Col span={12}>
         <h3 style={{fontWeight:"bold",marginTop:"20px"}}>异常情况描述:</h3>
          <p>{this.props.detail.exceptionDescribe}</p>
          
              {
                 this.props.detail.exceptionPhotoUrl?this.props.detail.exceptionPhotoUrl.split(',').map((item,index)=>{
                     return <Image style={{"marginRight":"20px"}}  key={index} width={100} src={item} />
                 }):[]
              }

         </Col> 
         
         <Col span={12}>

          <h3 style={{fontWeight:"bold",marginTop:"20px"}}>信息不一致描述:</h3>
              <p>{this.props.detail.handoverRemarks}</p>
              {
                 this.props.detail.handoverExceptionUrl?this.props.detail.handoverExceptionUrl.split(',').map((item,index)=>{
                     return <Image style={{"marginRight":"20px"}}  key={index} width={100} src={item} />
                 }):[]
              }
           </Col> 
       </Row>

        </Modal>
       </>
        )
    }
}

export default Detail
