import { Layout } from 'antd';
import React, { Component } from 'react'
import { setUserInfo } from '@/redux/actions/userInfo';
import { connect } from 'react-redux';
import SideMenu from "./SideMenu" //左边菜单栏
import TopHeader from "./TopHeader" //顶部区域
import BreadCrumb from './BreadCrumb';//面包屑
import MainContent from './MainContent';

//主页
class Index extends Component{
    render() {
        const { breadCrumb } = this.props;
        return (
             <div className="layout">
                 <Layout style={{minHeight:'100vh'}}>
                    <SideMenu></SideMenu>
                    <Layout>
                         <TopHeader/>
                         {breadCrumb.show ? <BreadCrumb /> : null}
                          <MainContent />
                    </Layout>
                 </Layout>
             </div>
        )
    }
}

const mapStateToProps=state=>state;

const mapDispatchToProps=dispatch=>({
   setUserInfo:data=>{
       dispatch(setUserInfo(data))
   }
})

export default connect(mapStateToProps,mapDispatchToProps)(Index)
