
import React, { Component } from 'react'
import { Modal,Form,Col,Tabs,Image,Table } from 'antd';
import { forkliftInforByUserId } from "../service"
const { TabPane } = Tabs;
/*
 *@Description: 叉车详情
 *@Author: 
 *@Date: 2020-09-10 18:53:59
*/
export class DetailInfo extends Component {

    state = { visible: false,dataSource:[] };

    constructor(props){
       super(props)
       console.log("组件详情")
    }
    showModal = () => {
      this.setState({
        visible: true,
      });
    };
    handleOk = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
    handleCancel = e => {
      console.log(e);
      this.props.close(true)
    };
    //props参数变化调用  props当前prop变化 this.props上一个props
    componentWillReceiveProps(props){
          if(props.visible===true){
            forkliftInforByUserId({
              id:props.detail.id
            },(res)=>{
              console.log(res)
              this.setState({
                dataSource:res.data
              })
            })
          } 
    }
    render() {

       const columns = [
         {
           title: '使用证号',
           dataIndex: 'licenseNo',
        
         },
         {
           title: '车辆牌号',
           dataIndex: 'vehicleNo',
         
         },
         {
          title: '厂牌型号',
          dataIndex: 'brandModel',
         
         },
         {
          title: '使用单位',
          dataIndex: 'companyName',
         },
         {
          title: '设备地址',
          dataIndex: 'companyAddr',
         },
         {
          title: '叉车类型',
          dataIndex: 'powerWay',
         }
       ];

        return (
            <>
            <Modal
              title="叉车台账详情"
              visible={this.props.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              destroyOnClose={true}
              width="800px"
              footer={null}
              maskClosable={false}
            >


         <Tabs defaultActiveKey="1" >
            <TabPane tab="基础信息" key="1">
             

            <Form  layout='inline'  labelCol={{span: 10,offset:0}}  wrapperCol={{ span: 16 }}>
             <Col span={8}>
                <Form.Item label="司机姓名">
                  {this.props.detail.userName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="联系电话">
                    {this.props.detail.phone}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="所属单位">
                   {this.props.detail.companyName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="证件编号">
                  {this.props.detail.driverNumber}
                </Form.Item>
             </Col>
             <Col span={16}>
                <Form.Item label="联系地址">
                   {this.props.detail.companyAddr}
                </Form.Item>
             </Col>
             <Col span={24}>
               <div >
                <Form.Item label="人像信息"   style={{marginTop:'10px'}}>
                    <Image  width="100px"   src={this.props.detail.facePhotoUrl} />
                </Form.Item>
               </div>
             </Col>
             <Col span={24}>
               <div >
                <Form.Item label="作业证附件"   style={{marginTop:'10px'}}>
                    <Image  width="100px"   src={this.props.detail.driverPhotoFrontUrl} />&nbsp;
                    <Image  width="100px"    src={this.props.detail.driverPhotoBackUrl} />
                </Form.Item>
               </div>
             </Col>

          </Form>


            </TabPane>
            <TabPane tab="准驾车辆" key="2">
               
               <Table dataSource={this.state.dataSource} columns={columns} />;

            </TabPane>

         </Tabs>
        </Modal>

          </>
        )
    }
}

export default DetailInfo
