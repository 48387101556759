
import React, { Component } from 'react'
import { Modal } from 'antd';
import PageConstant from "./constant"


export class Detail extends Component {

    state = { visible: false,tableData:[] };

    constructor(props){
       super(props)
       console.log("组件详情")
      //  console.log(this.props)
    }


    showModal = () => {
      this.setState({
        visible: true,
      });
    };
  
    handleOk = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
  
    handleCancel = e => {
      console.log(e);
      this.props.close(true)
    };
    componentDidMount(){
       console.log("哈哈")
    }
    //props参数变化调用  props当前prop变化 this.props上一个props
    componentWillReceiveProps(props){
      //  console.log(props)
      if(props.visible===true){

      } 
    }

    render() {



        return (
            <>
            <Modal
              title="行动轨迹"
              visible={this.props.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width="1000px"
              footer={null}
              maskClosable={false}
            >
              <iframe src={PageConstant.MAPURL+"&appkey="+PageConstant.APPKEY+'&account='+PageConstant.ACCOUNT+"&page="+PageConstant.PAGE+'&target='+this.props.detail.locationNum}  style={{"width":"100%","height":"600px","border":'0px'}} title="行动轨迹" />
            </Modal>
          </>
        )
    }
}

export default Detail
