import axios from '@/utils/axios'



function login(param, fun){
    axios.post('/api/syscompany/login', param).then((data) => {
      fun(data)
    })
}






export { login }