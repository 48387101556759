import axios from '@/utils/axios'

function findDict(param, fun){
    axios.post('/api/dict/findDict', param).then((data) => {
      fun(data)
    })
}



export { findDict }