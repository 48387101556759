import axios from '@/utils/axios'


function findWorkRecord(param, fun){
    axios.post('/api/workRecord/findWorkRecord', param).then((data) => {
      fun(data)
    })
}






export { findWorkRecord }