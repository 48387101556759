import React, { Component } from 'react'
import {Table, Button, Tabs } from 'antd'
import { findOverdueList,censusTenForkliftDate} from './service'
import { connect } from 'react-redux'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/title'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/toolbox'



const { TabPane } = Tabs

export class Supervise extends Component {
      //form表单
  formRef = React.createRef();
  constructor() {
    super()
    this.state = {
      tableData: [],
      pagination: {
        current: 1, //当前页面
        pageSize: 10, //页面尺寸
        total: 0,
        fold:true,
        position: ['bottomCenter'],
        showTotal: (total) => `共 ${total} 条`,
      },
    }
  }
  //查询数据
  handleQueryInfo = () => {
    let formValues = null
    if (this.formRef.current) {
      formValues = this.formRef.current.getFieldsValue()
    }

  findOverdueList(
      {
        currentPage: this.state.pagination.current,
        pageSize: this.state.pagination.pageSize,
        vehicleNo: formValues ? formValues.vehicleNo : '',
        companyName: formValues ? formValues.companyName : '',
        powerWay: formValues ? formValues.spotCheckMan : '',
        licenseNo: formValues ? formValues.licenseNo : '',
        companyId: this.props.userInfo.id,
        companyType:this.props.userInfo.companyType
      },
      (res) => {
        console.log('报表', res)
        if (!res) {
          return
        }

        this.setState({
          loading: false,
          tableData: res.data.list.map((item, index) => {
            item.key = index
            return item
          }),
          pagination: {
            current: this.state.pagination.current,
            pageSize: this.state.pagination.pageSize,
            total: res.data.total,
            position: ['bottomCenter'],
            showTotal: (total) => `共 ${total} 条`,
          },
        })
      },``
    )
  }
  onFormFinish = () => {}
  initLine(echartInstance,xdata,ydata,title) {
    //setOption
    echartInstance.setOption({

        "textStyle": {
            "fontFamily": "Din-Light"
        },
        "color": ["#123dac", "#73e2e2", "#ff7e85", "#9b52ff", "#fac524", "#46caff", "#a1e867", "#10b2b2", "#ec87f7", "#f4905a", "#00baba", "#facf24", "#e89d67", "#23c6c6", "#fa8699", "#40b7fc", "#006d75", "#595959", "#f4764f", "#a640fc", "#fda23f", "#2d7ae4", "#5092ff", "#9351ed", "#8a89fe", "#df89e8", "#2797ff", "#6ad089", "#7c92e8 "],
        "title": {
            "text": "",
            "left": "47%",
            "textStyle": {
                "fontSize": 24
            }
        },
        "legend": {
            "data": [title]
        },

        "tooltip": {
            "backgroundColor": "#fff",
            "trigger": "axis",
            "axisPointer": {
                "type": "none"
            },
            "textStyle": {
                "color": "#565656",
                "lineHeight": 28
            },
            "confine": true,
            "padding": 12,
            "extraCssText": "box-shadow: 0px 2px 8px 0px #cacaca;border-radius: 4px;opacity: 0.9;max-height: 100%;",
            "formatter": {
                "_custom": {
                    "type": "function",
                    "display": "<span>ƒ</span> (params)"
                }
            }
        },
        // "grid": {
        //     "left": 0,
        //     "right": 0,
        //     "top": 0,
        //     "bottom": 30
        // },
        "xAxis": {
            "type": "category",
            "boundaryGap": true,
            "data": xdata,
            "axisLabel": {
                "color": "#a0a9bc",
                //X轴标签 label 做了特殊处理，防止左右溢出
               formatter: (value, index) => {
                  // if (index === 0 || index === xdata.length - 1) {
                  //   return "";
                  // }
                  return value;
                }
            },
            "axisLine": {
                "show": false
            },
            "axisTick": {
                "show": false
            }
        },
        "yAxis": {
            "name": "",
            "nameTextStyle": {
                "color": "gray"
            },
            "type": "value",
            "axisLabel": {
                "color": "#a0a9bc",
                "inside": true,
                "margin": 0,
                "verticalAlign": "bottom"
            },
            "splitLine": {
                "lineStyle": {
                    "type": "dashed"
                }
            },
            "minInterval": 1,
            "axisLine": {
                "show": false
            },
            "axisTick": {
                "show": false
            }
        },
        "series": [{
            "name": title,
            "data": ydata,
            "type": "line",
            "smooth": true,
            "smoothMonotone": "x",
            "cursor": "pointer",
            "showSymbol": false,
            "lineStyle": {
                "shadowColor": "rgba(18,61,172,0.5)",
                "shadowBlur": 10
            }
        },]
    })
  }
  tabChangeCallback(key){
      console.log(key)

  }
  //组件加载
  componentDidMount() {
    //查询
    this.handleQueryInfo()

    console.log("userInfo数据信息",this.props)


    censusTenForkliftDate({
      companyId: this.props.userInfo.id
    },(res)=>{
        console.log("echarts图")
        console.log(res)

        let xlist=[]
        let ylist=[]
        res.data.forkliftList.forEach((item,index)=>{
           xlist.push(item.dateTime)
           ylist.push(item.num)
        })
        let forkliftNumLine=echarts.init(document.getElementById("forkliftNumLine"))
        this.initLine(forkliftNumLine,xlist,ylist,"叉车数量")


        if( this.props.userInfo.companyType==='0'){

          let _xlist=[]
          let _ylist=[]
          res.data.companyList.forEach((item,index)=>{
            _xlist.push(item.dateTime)
            _ylist.push(item.num)
          })
          let useLine=echarts.init(document.getElementById("useLine"))
          this.initLine(useLine,_xlist,_ylist,"使用单位")
        }

        let Xlist=[]
        let Ylist=[]
        res.data.userList.forEach((item,index)=>{
          Xlist.push(item.dateTime)
          Ylist.push(item.num)
        })
        let forkliftUserLine=echarts.init(document.getElementById("forkliftUserLine"))
        this.initLine(forkliftUserLine,Xlist,Ylist,"叉车司机")

    })
  }
  handleTableChange(pagination, filters, sorter, extra) {
    if (extra.action === 'paginate') {
      console.log('分页中')
      console.log(pagination)
      this.setState(
        {
          pagination: {
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            position: ['bottomCenter'],
            showTotal: (total) => `共 ${total} 条`,
          },
        },
        () => {
          console.log('修改成功')
          this.handleQueryInfo()
        },
      )
    }
  }

  render() {

    //列表
    const columns = [
      {
        title: '车牌号',
        dataIndex: 'vehicleNo',
        align: 'center',
      },
      {
        title: '使用证号',
        dataIndex: 'licenseNo',
        align: 'center',
      },
      {
        title: '厂牌型号',
        dataIndex: 'brandModel',
        align: 'center',
      },
      {
        title: '使用单位',
        dataIndex: 'companyName',
        align: 'center',
      },
      {
        title: '设备地址',
        dataIndex: 'equipmentAddr',
        align: 'center',
      },
      {
        title: '叉车类型',
        dataIndex: 'powerWay',
        align: 'center',
      },
      {
        title: '逾期时间',
        dataIndex: 'dueDate',
        align: 'center',
      },
      {
        title: '检验结论',
        dataIndex: 'checkoutResultName',
        align: 'center',
      },
      {
        title: '状态',
        dataIndex: 'forkliftWorkStateName',
        align: 'center',
      },
      {
        title: '操作',
        align: 'center',
        render: (h) => {
          return (
            <Button type="dashed" size="small">  查看 </Button>
          )
        },
      },
    ]
    return (
      <div>
       <Tabs tabPosition="left"  onChange={this.tabChangeCallback.bind(this)}>
          <TabPane tab="叉车数量" key="1" id="tabPane" forceRender={true} >
              <div id="forkliftNumLine"  style={{width:'80%',height:'300px'}}></div>
          </TabPane>

          {
            this.props.userInfo.companyType==='0'?
            <TabPane tab="使用单位" key="2" forceRender={true}>
              <div id="useLine"   style={{width:'1000%',height:'300px'}}></div>
            </TabPane>:null
          }


          <TabPane tab="叉车司机" key="3" forceRender="true">
              <div id="forkliftUserLine" style={{width:'1000%',height:'300px'}}></div>
          </TabPane>
        </Tabs>

       <h3 style={{marginTop:'20px'}}>年检提醒</h3>


        <Table
          columns={columns}
          rowKey={(record) => record.key}
          className="table-layout"
          onChange={this.handleTableChange.bind(this)}
          dataSource={this.state.tableData}
          pagination={this.state.pagination}
        ></Table>
      </div>
    )
  }
}

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Supervise)