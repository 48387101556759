import React, { Component } from 'react'
import { Upload,message,Modal } from 'antd';
import { PlusOutlined } from "@ant-design/icons"


export class UploadImage extends Component {


     //defaultProps 可以为 Class 组件添加默认 props,基于 static 的写法
  static defaultProps = {
    fileList:[],
    limitNum:4
  }

    constructor(props){
        super(props)
        this.state={
            previewVisible:false,
            previewImage: '',//展示图片
            previewTitle: '',//标题
            fileList:[]
        }

        console.log(this.props)

      let _fileList= this.props.fileList.map((item,index)=>{
             return {
                uid: item,
                name:item,
                status: 'done',
                url:item
             }
         })
         this.state.fileList=_fileList

    
    }
    handleCancel(){
        this.setState({
            previewVisible:false
        })
    }
    //预览图片
    handlePreview(file ){
        console.log(file)
        this.setState({
            previewVisible:true,
            previewImage:file.url
        })
    }
    
    //移除图片
    hanleRemove(file){
        console.log(file)
        let _index
        this.state.fileList.forEach((item,index)=>{
           if(item.url===file.url){
               _index=index
           } 
        })
       
        let _fileList=this.state.fileList
        _fileList.splice(_index,1)
        //删除previewImage
        this.setState({
            fileList:_fileList
        },()=>{

            message.success("删除成功")
        })
    }
    //上传状态改变
    handleChange(info){
        //正在上传
        if (info.file.status === 'uploading') {
              console.log("uploading...")
        }

        if (info.file.status === 'done') {
            console.log("done")
            console.log(info)
            const res=info.file.response
            console.log(res.status)
            if(res.status===200||res.status==="200"){
               message.success(res.message)
               console.log()
              let _fileList=this.state.fileList
               _fileList.push({
                uid: res.data.data,
                name:res.data.data,
                status: 'done',
                url:res.data.data
               })
               this.setState({
                  fileList:_fileList
               })
            }else{
                message.error(res.message)
            }
      }
        
    }
         //上传前钩子
     beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
              message.error('你只能上传 JPG/PNG 文件!');
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
              message.error('图片大小不能超过2M');
            }
            return isJpgOrPng && isLt2M;
    }

    render() {
        return (
            <>
            <Upload
             name="file"
             listType="picture-card"
             className="avatar-uploader"
             showUploadList={true}
             fileList={this.state.fileList}
             action="https://xjc.hyjckj.net/api/file/upload"
             beforeUpload={this.beforeUpload}
             onPreview={this.handlePreview.bind(this)}
             onChange={this.handleChange.bind(this)}
             onRemove={this.hanleRemove.bind(this)}
            >
            {this.state.fileList.length >= this.props.limitNum ? null :(<div>
               <PlusOutlined />
              <div style={{ marginTop: 8 }}>上传</div>
             </div>) }

            </Upload>

            <Modal
             visible={this.state.previewVisible}
             footer={null}
             title="照片"
             onCancel={this.handleCancel.bind(this)}
            >
            <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
           </Modal>
           </>

        )
    }
}

export default UploadImage
