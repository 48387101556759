import React, { Component } from 'react'
import 
{ 
  Skeleton,
  Form,
  Input,
  Table,
  Space,
  Button,

} from 'antd';

import { SearchOutlined,DownOutlined,UpOutlined,} from "@ant-design/icons"
import Detail from "./detail"
import { findHandoverList} from "./service"

import { connect } from 'react-redux'


export class MaintenanceCheckRecord extends Component {

  //form表单
  formRef = React.createRef();
  //构造器
  constructor(props){
      super(props)
      this.state={
        loading:false,
        fold:true,
        tableData:[],
        pagination:{
            current:1,//当前页面
            pageSize: 10, //页面尺寸
            total:0,
            position:["bottomCenter"],
            showTotal:(total)=>`共 ${total} 条`
        },
        dialogVisible:false,
        detail:{},
        maintenanceTypeList:[]
        
      }
  }
    //	分页、排序、筛选变化时触发
    handleTableChange(pagination, filters, sorter, extra){
      
        

      if(extra.action==="paginate"){
         console.log("分页中")
         console.log(pagination)
         this.setState({
          pagination:{
              current:pagination.current,
              pageSize:pagination.pageSize,
              total: pagination.total,
              position:["bottomCenter"],
              showTotal:(total)=>`共 ${total} 条`
          }
         },()=>{
             console.log("修改成功")
             this.handleQueryInfo()
         })
          
          
      }

  }
  //叉车档案信息
  showDetail(h){
    console.log(h)
    this.setState({
        detail:h,
        dialogVisible:true
    })
  }
  //关闭弹出框
  close(e){
   console.log("receive",e)
   this.setState({
    dialogVisible:false
   })
  }

   componentDidMount(){

       this.handleQueryInfo()
   }
       //表单提交
   onFormFinish(values){
       
    this.setState({
      pagination:{
          current:1,
          pageSize:10,
          total:0,
          position:["bottomCenter"],
          showTotal:(total)=>`共 ${total} 条`
      }
     },()=>{
         this.handleQueryInfo()
     })
    }
    //查询数据
    handleQueryInfo(){

      let formValues=null

      if(this.formRef.current){
          formValues=this.formRef.current.getFieldsValue()
      }
      findHandoverList({
          currentPage:this.state.pagination.current,
          pageSize:this.state.pagination.pageSize,
          vehicleNo:formValues?formValues.vehicleNo:'',
          companyName:formValues?formValues.companyName:'',
          spotCheckMan:formValues?formValues.spotCheckMan:'',
          companyId: this.props.userInfo.id,
          companyType:this.props.userInfo.companyType,
      },(res)=>{
          console.log(res)
          if(!res){
               return
          }
          this.setState({
              loading:false,
              tableData:res.data.list.map((item,index)=>{
                  item.key=index
                  return item
              }),
              pagination:{
                  current:this.state.pagination.current,
                  pageSize:this.state.pagination.pageSize,
                  total: res.data.total,
                  position:["bottomCenter"],
                  showTotal:(total)=>`共 ${total} 条`
              }
          })
         

      })
  }


  render() {
        //form布局
        const formLayout={
          layout:'inline',
          labelCol: { span: 8,offset:0 },
          wrapperCol: { span: 16 },
      }



      const columns=[
        {
          title:'车牌号',
          dataIndex:"vehicleNo",
          align:"center",
        },{
          title:'使用单位',
          dataIndex:"companyName",
          align:"center",          
        },
        {
          title:'交接人',
          dataIndex:"spotCheckMan",
          align:"center",          
        },
        {
          title:'交接时间',
          dataIndex:"spotCheckEndDate",
          align:"center",          
        },
        {
          title:'验收人',
          dataIndex:"handoverMan",
          align:"center",          
        },
        {
          title:'是否修好',
          dataIndex:"ifUpdateName",
          align:"center",          
        },
        {
          title:'操作',
          align:"center", 
          render:(h)=>{
              return (
                <Button type="dashed" size="small"  onClick={()=>{this.showDetail(h)}} >查看</Button>
              )
          }         
        }   
      ]
        return (
            <div className="shadow-radius">
               {this.state.loading&&<Skeleton  active  paragraph={{ rows: 20 }}></Skeleton>}    
               {this.state.loading||<div className="layout">
                    <Form {...formLayout}  ref={this.formRef}   onFinish={this.onFormFinish.bind(this)}>
                          <Form.Item label="车辆牌号" name="vehicleNo"  className="form-item-margin">
                              <Input  placeholder="请输入车牌牌号"  />
                          </Form.Item>

                          <Form.Item label="使用单位" name="companyName" className="form-item-margin">
                              <Input  placeholder="请输入使用单位名称"  />
                          </Form.Item>  
                      
                          <Form.Item label="交接人" name="spotCheckMan"  className="form-item-margin"  style={{display:this.state.fold?'none':null}}>
                              <Input  placeholder="请输入交接人" />
                          </Form.Item>

                          <Form.Item  className="form-item-margin">
                           <Space>
                             <Button type="primary" icon={<SearchOutlined />} htmlType="submit">查询</Button>
                        
                             {/* <Button type="ghost" icon={<VerticalAlignBottomOutlined />} >导出</Button> */}
                             { 
                               this.state.fold?
                              <Button type="link"  onClick={()=>{ this.setState({fold:false}) }}>展开<DownOutlined /></Button>:
                              <Button type="link"  onClick={()=>{ this.setState({fold:true}) }} >收起<UpOutlined /></Button>
                             }
                             </Space>
                          </Form.Item>
                      {/* <Row gutter={16} style={{width:"100%",marginTop:'20px'}} >

                      </Row> */}
                    </Form>

                    <Table columns={columns}   rowKey={record => record.id}  className="table-layout"  onChange={this.handleTableChange.bind(this)}  dataSource={this.state.tableData}  pagination={this.state.pagination}></Table>
                  
                     <Detail  detail={this.state.detail} visible={this.state.dialogVisible} close={this.close.bind(this)}></Detail>


               </div>}
            </div>
        )
    }
}



const mapStateToProps = state=>state

const mapDispatchToProps=dispatch=>({})

export default connect(mapStateToProps,mapDispatchToProps)(MaintenanceCheckRecord);
