import axios from '@/utils/axios'




function getMaintainList(param, fun){
    axios.post('/api/sysmaintain/getMaintainList', param).then((data) => {
      fun(data)
    })
}



function findDict(param, fun){
    axios.post('/api/dict/findDict', param).then((data) => {
      fun(data)
    })
}


function getMaintainDetailBy(param, fun){
  axios.post('/api/sysmaintain/getMaintainDetailBy', param).then((data) => {
    fun(data)
  })
}





export { getMaintainList,findDict,getMaintainDetailBy}