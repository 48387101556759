
import React, { Component } from 'react'
import { Modal,Form,Col,Tabs,Input,Row,Space,Select,Table, message,Button} from 'antd';
import  UploadImage from "@/components/UploadImage.js"
import { companyFindList,updatDriverInformation,forkliftInforByUserId,insertForkliftUser} from "../service"
import { MinusOutlined , PlusOutlined } from "@ant-design/icons"
import AllForkLift from "./AllForkLift"
const { TabPane } = Tabs;

const { Option } = Select;



/*
 *@Description: 叉车详情
 *@Author: Tp
 *@Date: 2020-09-10 18:53:59
*/
export class DetailUpdate extends Component {

    state = {visible: false,companyList:[],
      dataSource:[],
      confirmLoading:false,

      allforkliftVisible:false ,
      tab:'1'
   }
      

    workCertificateRef = React.createRef();

    avatarRef=React.createRef()

     //form表单
    formRef = React.createRef();



    constructor(props){
       super(props)
       console.log("组件详情")

    }
    componentWillReceiveProps(props){
       if(props.visible===true){
          //查询车辆
         forkliftInforByUserId({
            id:props.detail.id
          },(res)=>{
            console.log(res)
            this.setState({
              dataSource:res.data
            })
          })


       }
    }

    handleCancel = e => {
      console.log(e);
      this.props.close(true)
    };
    handleChange = info => {
      if (info.file.status === 'uploading') {
        this.setState({ loading: true });
        return;
      }
      if (info.file.status === 'done') {

      }
    };
    handleComfirm(){
       if(this.state.tab==='1'){
         console.log( this.formRef.current)
         //  let formValues=this.formRef.current.getFieldValue()
          
          this.formRef.current.submit()
       }else{


         if(this.state.dataSource.length===0){
            message.error("请至少选择一台车辆！")
            return
         }
          this.setState({
             confirmLoading:true
          })

         
         insertForkliftUser({
            userId:this.props.detail.id,
            forkliftIds:this.state.dataSource.map(item=>item.id).join(',')
         },(res)=>{
            this.setState({
               confirmLoading:false
            })
            if(res){
               message.success(res.message)
               this.props.close(true)
            }
         })
       }

       
    }
    formFinish(value){
       console.log(value)
       this.setState({
         confirmLoading:true
       })
       updatDriverInformation({
         name:value.driverName,
         phone:value.phone,
         companyId:value.companyName,
         idNumber:value.identityNum,
         driverNumber:value.certificateNum,
         driverPhotoFrontUrl:this.workCertificateRef.current.state.fileList[0].url,
         driverPhotoBackUrl:this.workCertificateRef.current.state.fileList[1].url,
         facePhotoUrl:this.avatarRef.current.state.fileList[0].url,
         id:this.props.detail.id
       },(res)=>{
          console.log(res)
          this.setState({
            confirmLoading:false
          })
          if(res){
            message.success(res.message)
            this.props.close(true)
          }
       })

    }
    componentDidMount(){

      companyFindList({},(res)=>{
         console.log(res)
         this.setState({companyList:res.data})
      })

    }
     
    showAllCar(){

      this.setState({
         allforkliftVisible:true
      })
    }
    close(values){

      if(values){
          
         for(let a of this.state.dataSource){
            for(let b of values){
               if(a.id===b.id){
                  message.error("存在相同的车辆，请重新选择！")
                  return 
               }
            }
         }
      }
      this.setState({
         allforkliftVisible:false
      })


      console.log(values)
       let connctData=this.state.dataSource.concat(values)
       this.setState({
          dataSource:connctData
       })
   
    }
    //减少
    reduce(){
   

      let data=JSON.parse(JSON.stringify(this.state.dataSource) )

      data.pop()
     this.setState({
        dataSource: data
     })
     
    }
    tabChange(value){
       console.log(value)
       this.setState({
          tab:value
       })
    }
    render() {

      const columns = [
         {
           title: '使用证号',
           dataIndex: 'licenseNo',
           align:"center"
         },
         {
           title: '车辆牌号',
           dataIndex: 'vehicleNo',
           align:"center"
         },
         {
          title: '厂牌型号',
          dataIndex: 'brandModel',
          align:"center"
         },
         {
          title: '使用单位',
          dataIndex: 'companyName',
          align:"center"
         },
         {
          title: '设备地址',
          dataIndex: 'companyAddr',
          align:"center"
         },
         {
          title: '叉车类型',
          dataIndex: 'powerWay',
          align:"center"
         }
       ];


        return (
            <>
            <Modal
              title="修改司机信息"
              visible={this.props.visible}
              destroyOnClose={true}
              onCancel={this.handleCancel.bind(this)}
              onOk={this.handleComfirm.bind(this)}
              width="900px"
              maskClosable={false}
              confirmLoading={this.state.confirmLoading}
            >
        <Tabs defaultActiveKey="1"  onChange={this.tabChange.bind(this)} style={{marginTop:'-12px'}} >
            <TabPane tab="基础信息" key="1">
            <Form  layout='inline'  ref={this.formRef}   onFinish={this.formFinish.bind(this)}  labelCol={{span: 10,offset:0}}  wrapperCol={{ span: 16 }}  >
            <Space direction="vertical" size="large">
            <Row  gutter={20}>
             <Col span={8}>
                <Form.Item label="司机姓名" name="driverName" initialValue={this.props.detail.userName} rules={[{ required: true, message: '请输入司机姓名' }]} hasFeedback>
                     <Input placeholder="请输入司机姓名"></Input>
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="联系电话" name="phone" initialValue={this.props.detail.phone} rules={[{ required: true, message: '请输入联系电话' }]} hasFeedback>
                   <Input placeholder="请输入联系电话"></Input>
                </Form.Item>
             </Col>

             <Col span={8}>
                <Form.Item label="所属单位" name="companyName"  initialValue={this.props.detail.companyId} rules={[{ required: true, message: '请输入联系电话' }]} hasFeedback>
                   <Select placeholder="请选择所属单位" style={{width:'180px'}} allowClear>
                    {this.state.companyList.length!==0? this.state.companyList.map((item,index)=>{
                           return (
                               <Option value={item.id+''}  key={item.id}>{item.companyName}</Option>
                            )
                        })
                     :null}

                   </Select>

                </Form.Item>

             </Col>
             </Row>
             <Row  gutter={20}>
             <Col span={8} >
                <Form.Item label="证件编号" name="certificateNum" initialValue={this.props.detail.driverNumber}>
                   <Input placeholder="请输入发动机编号"></Input>
                </Form.Item>
             </Col>

             <Col span={8}>
                <Form.Item label="身份证" name="identityNum" initialValue={this.props.detail.idNumber} rules={[{ required: true, message: '请输入身份证' }]} hasFeedback>
                   <Input placeholder="请输入身份证"></Input>
                </Form.Item>
             </Col>

          </Row>
           <Row gutter={20}>

            <Col span={12} >
                  <Form.Item label="作业证附件">
                    <UploadImage ref={this.workCertificateRef}  limitNum={2}  fileList={[this.props.detail.driverPhotoFrontUrl,this.props.detail.driverPhotoBackUrl]} />
                   
                  </Form.Item>
               </Col>
               <Col span={12} >
                  <Form.Item label="人像信息">
                     <UploadImage ref={this.avatarRef}  limitNum={1}  fileList={[this.props.detail.facePhotoUrl]} />
                  </Form.Item>
               </Col>

           </Row>

            </Space>
          </Form>

            </TabPane>
            <TabPane tab="准驾车辆" key="2">
              <Space style={{margin:'10px 0px'}}>

               <Button type="dashed" onClick={this.showAllCar.bind(this)} size="mini" shape="circle" icon={<PlusOutlined />}  />
            
               <Button type="dashed" size="mini" onClick={this.reduce.bind(this)} shape="circle" icon={<MinusOutlined />}  />
               </Space>
               <Table   rowKey={record => record.id} dataSource={this.state.dataSource} columns={columns} />;

           </TabPane>

        </Tabs>
        <AllForkLift detail={this.props.detail} visible={this.state.allforkliftVisible} close={this.close.bind(this)}></AllForkLift>
     </Modal>
    </>
        )
    }
}

export default DetailUpdate
