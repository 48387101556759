import axios from '@/utils/axios'



function findList(param, fun){
    axios.post('/api/sysuser/findList', param).then((data) => {
      fun(data)
    })
}


function forkliftInforByUserId(param, fun){
  axios.post('/api/sysuser/forkliftInforByUserId', param).then((data) => {
    fun(data)
  })
}


function companyFindList(param, fun){
  axios.post('/api/syscompany/findList', param).then((data) => {
    fun(data)
  })
}


function updatDriverInformation(param, fun){
  axios.post('/api/sysuser/updatDriverInformation', param).then((data) => {
    fun(data)
  })
}


function findAllCompanyList(param, fun){
  console.log(param)
  axios.post('/api/sysForklift/findList', param).then((data) => {
    fun(data)
  })
}


function insertForkliftUser(param, fun){
  console.log(param)
  axios.post('/api/sysuser/insertForkliftUser', param).then((data) => {
    fun(data)
  })
}

function deleteUser(param, fun){
  console.log(param)
  axios.post('/api/sysuser/deleteUser', param).then((data) => {
    fun(data)
  })
}


function checkUserState(param, fun){
  console.log(param)
  axios.post('/api/sysuser/checkUserState', param).then((data) => {
    fun(data)
  })
}


export { findList,forkliftInforByUserId,companyFindList,updatDriverInformation,findAllCompanyList,insertForkliftUser,deleteUser,checkUserState}