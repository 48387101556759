import React, { Component } from 'react'

import { Modal,Table} from 'antd';

import { findAllCompanyList} from "../service"

export class AllForkLift extends Component {


    constructor(props){
        super(props)
        this.state={
            loading:true,
            fold:true,
            tableData:[
    
            ],
            pagination:{
                current:1,//当前页面
                pageSize: 10, //页面尺寸
                // total:0,
                position:["bottomCenter"],
                showTotal:(total)=>`共 ${total} 条`
            },
            selectedRowKeys:[],
            selectedData:[]
          }
    }


        //	分页、排序、筛选变化时触发
  handleTableChange(pagination, filters, sorter, extra){
      
    if(extra.action==="paginate"){
       console.log("分页中")
     
       this.setState({
        pagination:{
            current:pagination.current,
            pageSize:pagination.pageSize,
            total: pagination.total,
            position:["bottomCenter"],
            showTotal:(total)=>`共 ${total} 条`
        }
       },()=>{
        
           this.handleQueryInfo()
       })

}
}

componentWillReceiveProps(props){
 if(props.visible){
    console.log("allforklift",props)
    this.handleQueryInfo()
     this.setState({
        selectedRowKeys:[],
        selectedData:[]
     })
 }
}
  
    handleOk(){
        this.props.close(this.state.selectedData)
    }
    handleCancel(){
        this.props.close(false)
    }
    handleQueryInfo(){

                  //查询所有车辆
                  findAllCompanyList({
                    companyId:this.props.detail.companyId,
                    currentPage:this.state.pagination.current,//当前页面
                    pageSize: this.state.pagination.pageSize  , //页面尺寸
                  },(res)=>{
                     console.log("所有车辆",res)
                     if(!res){
                        return
                     }
                     this.setState({
                        tableData:res.data.list,
                       pagination:{
                          current:this.state.pagination.current,
                          pageSize:this.state.pagination.pageSize,
                          total: res.data.total,
                          position:["bottomCenter"],
                          showTotal:(total)=>`共 ${total} 条`
                      }
                     })
                  })
    }
    onSelectChange(value,row){
        this.setState({ selectedRowKeys:value,selectedData:row });
        
    }
    componentDidMount(){
        
       

    }
    render() {

        const columns = [
            {
              title: '使用证号',
              dataIndex: 'licenseNo',
              align:"center"
            },
            {
              title: '车辆牌号',
              dataIndex: 'vehicleNo',
              align:"center"
            },
            {
             title: '厂牌型号',
             dataIndex: 'brandModel',
             align:"center"
            },
            {
             title: '使用单位',
             dataIndex: 'companyName',
             align:"center"
            },
            {
             title: '设备地址',
             dataIndex: 'companyAddr',
             align:"center"
            },
            {
             title: '叉车类型',
             dataIndex: 'powerWay',
             align:"center"
            }
          ];
        return (
            <Modal
              title="所有车辆"
              visible={this.props.visible}
              onOk={this.handleOk.bind(this)}
              onCancel={this.handleCancel.bind(this)}
              destroyOnClose={true}
              width="900px"
             
              maskClosable={false}
            >
                <Table  onChange={this.handleTableChange.bind(this)}  rowSelection={{selectedRowKeys:this.state.selectedRowKeys,onChange:this.onSelectChange.bind(this)}}   rowKey={record => record.id}  pagination={this.state.pagination} dataSource={this.state.tableData} columns={columns} />
            </Modal>
        )
    }
}

export default AllForkLift
