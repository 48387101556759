import React, { Component } from 'react'

export default class About extends Component {
    render() {
        return (
            <div>
               叉车系统关于功能
            </div>
        )
    }
}
