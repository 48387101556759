import axios from '@/utils/axios'


function findHandoverList(param, fun){
    axios.post('/api/spotCheck/findHandoverList', param).then((data) => {
      fun(data)
    })
}


export { findHandoverList}