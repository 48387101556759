import React, { Component } from 'react'

import { Table, Tag, Space } from 'antd';

export default class BasicForm extends Component {


       constructor(props){
           super(props)
           this.state={
            dataSource:[
              {
                key: '1',
                name: 'John Brown',
                age: 32,
                address: 'New York No. 1 Lake Park',
                tags: ['nice', 'developer'],
              },
              {
                key: '2',
                name: 'Jim Green',
                age: 42,
                address: 'London No. 1 Lake Park',
                tags: ['loser'],
              },
              {
                key: '3',
                name: 'Joe Black',
                age: 32,
                address: 'Sidney No. 1 Lake Park',
                tags: ['cool', 'teacher'],
              },
            ],
            columns:[
              {
                title:"Name",
                dataIndex:"name",
                sorter: (a, b) => a.name.length - b.name.length,
                render:text=>{
                  return (<>{text}</>)
                }
              },
              {
                title:"Age",
                sorter: true,
                dataIndex:"age"
              },
              {
                title: 'Address',
                sorter: true,
                dataIndex:"address"
              },
              {
                title:"Tags",
                sorter: true,
                dataIndex:"tags",
                render:tags=>(
                  <>
                  {tags.map(tag => {

                    let color = tag.length > 5 ? 'geekblue' : 'green';
                    if (tag === 'loser') {
                      color = 'volcano';
                    }
                    return (
                      <Tag color={color} key={tag}>
                        {tag.toUpperCase()}
                      </Tag>
                    );
                  })}
                </>
                )
              },
              {
                title: 'Action',
                key: 'action',
                render: (text, record) => {
                  return (
                   <Space size="middle">

                </Space>
                  )
                }






              },
            ]
         }
       }

    //组件加载
    componentDidMount(){

        console.log("basicForm")
    }

    render() {

  console.log(this.state.columns)

        return (
            <div className="shadow-radius">
             <Table dataSource={this.state.dataSource} columns={this.state.columns} />;
            </div>
        )
    }
}
