import React, { Component } from 'react'
import { Avatar, Dropdown, Menu,
     //Badge 
    
    } from 'antd';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { setCollapse, setBreadCrumb, setTags, setTheme } from '@/redux/actions/setting';
import { emptyTag, addTag } from '@/redux/actions/tagList';
import { setUserInfo } from '@/redux/actions/userInfo';
import { UserOutlined , 
        LogoutOutlined,
        MenuUnfoldOutlined,
        MenuFoldOutlined,
        SettingOutlined,
        //BellOutlined,
        CaretDownOutlined
    } from "@ant-design/icons"
import  FullScreen from "@/components/FullScreen.js"
import  BasicDrawer from '@/components/BasicDrawer';
import Tags from "./Tags"




export class TopHeader extends Component {
   state = { visible: false };

   //初始化
   componentDidMount(){
      let userInfo=localStorage.getItem("userInfo") && JSON.parse(localStorage.getItem('userInfo'))
      if(userInfo){
          this.props.setUserInfo(userInfo)
      } else{
          this.props.setUserInfo({})
          this.props.history.push('/login')
      }

   }

//折叠 展开
toggle = () => {
    this.props.setCollapse({ isCollapsed: !this.props.collapse.isCollapsed });
    console.log(this.props.collapse.isCollapsed )
};
//设置按钮
setting = () => {
    this.setState({ visible: true });
};

//设置
toNews = () => {
    console.log("news")
    // this.handClickTag('/news');
    // this.props.history.push('/news');
};


//设置功能
onClose = () => {
    this.setState({ visible: false });
};
onChangeTags = checked => {
    this.props.setTags({ show: checked });
    localStorage.setItem('tags', JSON.stringify({ show: checked }));
    this.onClose();
};
onChangeBreadCrumb = checked => {
    this.props.setBreadCrumb({ show: checked });
    localStorage.setItem('breadCrumb', JSON.stringify({ show: checked }));
    this.onClose();
};
onChangeTheme = checked => {
    this.props.setTheme({ type: checked ? 'dark' : 'light' });
    localStorage.setItem('theme', JSON.stringify({ type: checked ? 'dark' : 'light' }));
    this.onClose();
};
handleLogout(){
    this.props.history.push("/login")
}

 render() {
        //下拉列表菜单
        const DropDownList=(
            <Menu className="drop-list">
                  <Menu.Item key="user">
                      <UserOutlined />
                      {Object.keys(this.props.userInfo).length > 0 && this.props.userInfo.role.name}
                  </Menu.Item>
                  <Menu.Item key="logout" onClick={this.handleLogout.bind(this)}>
                     <LogoutOutlined />
					退出登录
				</Menu.Item>
            </Menu>
        )

        //路由标签
        const { tags } = this.props;

        return (
            <div className="top-header"> 
                 <div className="top-header-inner">
                     {/**折叠按钮样式变化**/}
                     { this.props.collapse.isCollapsed? <MenuUnfoldOutlined  className="trigger" onClick={this.toggle} />:<MenuFoldOutlined  className="trigger" onClick={this.toggle} /> }
                     <div className="header-title">智慧叉车管理平台</div>
                     <div className="header-right">
                          <div className="full-screen">
                               <FullScreen />
                          </div>
                          {/**设置**/}
                          <div className="setting">
							<SettingOutlined style={{ fontSize: '21px', cursor: 'pointer' }} onClick={this.setting} />
					    	</div>
                        
                         {/**个人信息**/}
                        <div className="dropdown-wrap" id="dropdown-wrap" style={{ cursor: 'pointer' }}>
                            <Dropdown getPopupContainer={() => document.getElementById('dropdown-wrap')} overlay={DropDownList}>
                                <div>
                                    <Avatar shape="square" size="default" icon={<UserOutlined />} />	
                                    <CaretDownOutlined style={{ color: 'rgba(0,0,0,.3)', cursor: 'pointer' }} />
                                </div>
                            </Dropdown>
                        </div>
                     </div>
                 </div>
                 { tags.show ? <Tags /> : null}
                 <BasicDrawer title="系统设置" closable onClose={this.onClose} visible={this.state.visible} onChangeTags={this.onChangeTags} onChangeBreadCrumb={this.onChangeBreadCrumb} onChangeTheme={this.onChangeTheme} {...this.props} />
            </div>
        )
    }
}

const mapStateToProps = state=>state

const mapDispatchToProps = dispatch => ({
	setCollapse: data => {
		dispatch(setCollapse(data));
	},
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	},
	emptyTag: () => {
		dispatch(emptyTag());
	},
	addTag: data => {
		dispatch(addTag(data));
	},
	setBreadCrumb: data => {
		dispatch(setBreadCrumb(data));
	},
	setTags: data => {
		dispatch(setTags(data));
	},
	setTheme: data => {
		dispatch(setTheme(data));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopHeader))
