import * as actionTypes from '../constants/index';

const setUserInfo = (data) => {
    console.log(data)
    return {
        type: actionTypes.SET_USERINFO,
        data
    }
};
export {setUserInfo};
