import React, { Component } from 'react'
import 
{ 
  Skeleton,
  Form,
  Input,
  Button,
  Space,
  DatePicker,
  Divider,
  Table,
  Modal,
  Col,
  Image,
  Tag
} from 'antd';

import { SearchOutlined,DownOutlined,UpOutlined} from "@ant-design/icons"
import moment from 'moment'
import { equipmentList} from "./service"

import { connect } from 'react-redux'

export class Igition extends Component {

    //表单初始化值
    inintFormValues={
        carNum:"",
        equipmentNum:'',
        installTime:'',
        installMan:''
    }

    constructor(props){
        super(props)
        this.state={
            loading:true,
            fold:true,
            visible:false,
            tableData:[],
            pagination:{
                current:1,//当前页面
                pageSize: 10, //页面尺寸
                // total:0,
                position:["bottomCenter"],
                showTotal:(total)=>`共 ${total} 条`
            },
            detail:{},

        }

       this.formRef = React.createRef();
       this.insertFormRef=React.createRef()

    }
    componentDidMount(){
        console.log("componentDidMount")

      //初始化查询
      this.handleQueryInfo()
    }

    
    componentWillUnmount() {
        this.setState = ()=>false;
    }
    //表单提交
    onFormFinish(values){
        this.setState({
            pagination:{
                current:1,
                pageSize:10,
                total:0,
                position:["bottomCenter"],
                showTotal:(total)=>`共 ${total} 条`
            }
           },()=>{
               this.handleQueryInfo()
           })
    }
    
    //	分页、排序、筛选变化时触发
    handleTableChange(pagination, filters, sorter, extra){
      
        if(extra.action==="paginate"){
           console.log("分页中")
           console.log(pagination)
           this.setState({
            pagination:{
                current:pagination.current,
                pageSize:pagination.pageSize,
                total: pagination.total,
                position:["bottomCenter"],
                showTotal:(total)=>`共 ${total} 条`
            }
           },()=>{
               console.log("修改成功")
               this.handleQueryInfo()
           })

        }

    }
    //查询数据
    handleQueryInfo(params){

        let formValues=null

        if(this.formRef.current){
            formValues=this.formRef.current.getFieldsValue()
        }
       
        equipmentList({
            companyId: this.props.userInfo.id,
            companyType:this.props.userInfo.companyType,
            currentPage:this.state.pagination.current,
            pageSize:this.state.pagination.pageSize,
            vehicleNo:formValues?formValues.carNum:'',
            equipmentNum:formValues?formValues.equipmentNum:'',
            installPerson:formValues?formValues.installMan:'',
            startDate:formValues?formValues.installTime?moment(formValues.installTime[0]).format('YYYY-MM-DD'):"":"",
            endDate:formValues?formValues.installTime?moment(formValues.installTime[0]).format('YYYY-MM-DD'):"":""
        },(res)=>{
            console.log(res)
            if(!res){
                 return
            }
            this.setState({
                loading:false,
                tableData:res.data.list.map((item,index)=>{
                    item.key=index
                    return item
                }),
                pagination:{
                    current:this.state.pagination.current,
                    pageSize:this.state.pagination.pageSize,
                    total: res.data.total,
                    position:["bottomCenter"],
                    showTotal:(total)=>`共 ${total} 条`
                }
            })
           

        })
    }
    openDialog(h){
        console.log("哈哈")
        console.log(h)
        this.setState({
            visible:true,
            detail:h
       })
       
    }


    closeDialog(e){
         console.log(e)
         this.setState({
             visible:false
         })
    }


    render() {
        //form布局
        const formLayout={
            layout:'inline',
            labelCol: { span: 8,offset:0 },
            wrapperCol: { span: 16 },
        }
        //日期选择控件
        const { RangePicker } = DatePicker;

       //table 的列头
       
       const columns=[
        {
            title:'设备编号',
            dataIndex:"equipmentNum",
            align:"center",
          
         }, 
         {
             title:'设备名称',
             dataIndex:"equipmentName",
             align:"center",

         },
         {
            title:'车牌号',
            dataIndex:"vehicleNo",
            align:"center",
         },

         {
            title:'安装时间',
            dataIndex:"installDate",
            align:"center",
          
         }, 
         {
            title:'安装人员',
            dataIndex:"installPerson",
            align:"center",
          
         },
         {
            title:'状态',
            align:"center",
            render:(h)=>{
              if(h.state==="0"){
                return  <Tag color="blue">{h.stateName}</Tag>
             }else {
                return <Tag color="red">{h.stateName}</Tag>
             }
            }
         },
         {
            title:'操作',
            align:"center",
            render:(h)=>{
                  return (
                      <>
                      <Space>
                        <Button type="dashed"  size="small" onClick={()=>{ this.openDialog(h) }}>查看详情</Button>
                      </Space>
                      </>
                  )
            },
         }, 
      ]

       const DetailDialog=()=>{
        return (
        <Modal
            title="点火控制设备"
            visible={this.state.visible}
            footer={null}
            onCancel={this.closeDialog.bind(this)}
            width="800px"
            maskClosable={false}
            onOk={()=>{this.setState({
            visible:false
            })}
            }
            >
            <h3 style={{fontWeight:"bold"}}>基本信息</h3>
           <Form  layout='inline'  labelCol={{span: 10,offset:0}}  wrapperCol={{ span: 16 }}>
             <Col span={8}>
                <Form.Item label="设备名称">
                     {this.state.detail.equipmentName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="车牌号">
                   {this.state.detail.vehicleNo}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="安装人员">
                    {this.state.detail.installPerson}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="设备编号">
                   {this.state.detail.equipmentNum}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="安装时间">
                    {this.state.detail.installDate}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="状态">
                    {this.state.detail.stateName}
                </Form.Item>
             </Col>
             <Col span={12}>
                <Form.Item label="所属单位">
                  {this.state.detail.companyName}
                </Form.Item>
             </Col>
             <Col span={12}>
             <Form.Item label="联系地址">
                   {this.state.detail.companyAddr}
             </Form.Item>
             </Col>

          </Form>
          
          <h3 style={{fontWeight:"bold",marginTop:"20px"}}>安装现场图片</h3>
              {
                 this.state.detail.qrCodePhoto?this.state.detail.forkliftEquipmentUrl.split(',').map((item,index)=>{
                     return <Image style={{"marginRight":"20px"}}  key={index} width={100} src={item} />
                 }):[]
              }
        </Modal>
        )
      }

        return (
            <div>
                 {this.state.loading&&<Skeleton  active  paragraph={{ rows: 20 }}></Skeleton>}    
                 {this.state.loading||<div className="layout">
                   <Form {...formLayout}  ref={this.formRef} size="middle" onFinish={this.onFormFinish.bind(this)}  initialValues={this.inintFormValues}>
                      
                       <Form.Item label="设备编号" name="equipmentNum"  className="form-item-margin">
                            <Input  placeholder="请输入设备编号" />
                        </Form.Item>
                      
                        <Form.Item label="车辆牌号" name="carNum"  className="form-item-margin">
                            <Input  placeholder="请输入车牌牌号"    />
                        </Form.Item>

                        <Form.Item label="安装时间" name="installTime" style={{display:this.state.fold?'none':null}}  className="form-item-margin">
                            <RangePicker   placeholder={['开始时间','结束时间']}   /> 
                        </Form.Item>
                        <Form.Item label="安装人员" name="installMan" style={{display:this.state.fold?'none':null}}  className="form-item-margin">
                            <Input  placeholder="请输入安装人员"  />
                        </Form.Item>

                        <Form.Item  className="form-item-margin">
                           <Space>
                             <Button type="primary" icon={<SearchOutlined />} htmlType="submit">查询</Button>
                            
                             { 
                               this.state.fold?
                              <Button type="link"  onClick={()=>{ this.setState({fold:false}) }}>展开<DownOutlined /></Button>:
                              <Button type="link"  onClick={()=>{ this.setState({fold:true}) }} >收起<UpOutlined /></Button>
                             }
                             </Space>
                        </Form.Item>
                    </Form>
                     <Divider />

                     <Table columns={columns}   onChange={this.handleTableChange.bind(this)}  dataSource={this.state.tableData}  pagination={this.state.pagination}>

                     </Table>
                     <DetailDialog></DetailDialog>

              </div>}    
            </div>
        )
    }
}

const mapStateToProps = state=>state

const mapDispatchToProps=dispatch=>({})

export default connect(mapStateToProps,mapDispatchToProps)(Igition);


