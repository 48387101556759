import React, { Component } from 'react'
import 
{ 

  Tabs 

} from 'antd';
import Gps from "./gps/index.js"
import Ignition from "./igition/index.js"
const { TabPane } = Tabs;



export class StandingBook extends Component {

  render() {


        return (
            <div className="shadow-radius">


                <Tabs  >
                    <TabPane tab="点火控制设备" key="1">
                           <Ignition></Ignition>
                    </TabPane>
                    <TabPane tab="GPS定位设备" key="2">
                           <Gps></Gps>
                    </TabPane>
                </Tabs>
                

              
            </div>
        )
    }
}

export default StandingBook
