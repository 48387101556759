import React, { Component } from 'react'
import { Col, Row } from 'antd'
import { dataInfo} from './service'
// import DashboardConstant from './constant'
import { connect } from 'react-redux'
import Supervise from './supervise'
import  dashboard0Img from "@/assets/images/dashboard_0.png"
import  dashboard1Img from "@/assets/images/dashboard_1.png"
import  dashboard2Img from "@/assets/images/dashboard_2.png"
import  dashboard3Img from "@/assets/images/dashboard_3.png"
import  dashboard4Img from "@/assets/images/dashboard_4.png"
import  dashboard5Img from "@/assets/images/dashboard_5.png"
// import
class Index extends Component {
  formRef = React.createRef()

  constructor() {
    super()
    this.state = {
      loading: true,
      fold: true,
      info: {},

    }
  }
  componentDidMount() {
    console.log(this.props.userInfo)

    dataInfo(
      { companyId: this.props.userInfo.id,
        companyType:this.props.userInfo.companyType
      },
      (res) => {
        console.log(res)
        if (res) {
          this.setState({
            info: res.data,
          })
        }
      },
    )

  }

  onFormFinis = () => {}
  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = () => {
    this.setState({
      ModalText: 'The modal will be closed after two seconds',
      confirmLoading: true,
    })
    setTimeout(() => {
      this.setState({
        visible: false,
        confirmLoading: false,
      })
    }, 2000)
  }
  handleCancel = () => {
    console.log('Clicked cancel button')
    this.setState({
      visible: false,
    })
  }
  onFormFinish = () => {}

  render() {
    // const mapUrl =
    //   DashboardConstant.MAPURL +
    //   '&appkey=' +
    //   DashboardConstant.APPKEY +
    //   '&account=' +
    //   DashboardConstant.ACCOUNT +
    //   '&page=' +
    //   DashboardConstant.PAGE +
    //   '&target=' +
    //   DashboardConstant.TARGET

    return (
      <div >
        <Row gutter={16} style={{ marginBottom: '30px' }}>
            <Col span={4}>
                <div className="dashboard-card-shadow dashboard-card-layout">
                  <div>
                    <div style={{fontWeight:"600",fontSize:"14px"}}>叉车数量</div>
                    <div className="h3"> {this.state.info.forkliftSum}</div>
                  </div>
                  <img src={dashboard0Img} alt=""  style={{width:'60px',height:'auto'}} />
                </div>
            </Col>
            <Col span={4}>
                <div className="dashboard-card-shadow dashboard-card-layout">
                  <div>
                    <div style={{fontWeight:"600",fontSize:"14px"}}>在用台数</div>
                    <div className="h3">  {this.state.info.useNumber}</div>
                  </div>
                  <img src={dashboard2Img} alt=""  style={{width:'60px',height:'auto'}} />
                </div>
            </Col>

            <Col span={4}>
                <div className="dashboard-card-shadow dashboard-card-layout">
                  <div>
                    <div style={{fontWeight:"600",fontSize:"14px"}}>使用单位</div>
                    <div className="h3">   {this.state.info.unitNum}</div>
                  </div>
                  <img src={dashboard3Img} alt=""  style={{width:'60px',height:'auto'}} />
                </div>
            </Col>
            <Col span={4}>
                <div className="dashboard-card-shadow dashboard-card-layout">
                  <div>
                    <div style={{fontWeight:"600",fontSize:"14px"}}>管理员</div>
                    <div className="h3">  {this.state.info.safeNum}</div>
                  </div>
                  <img src={dashboard4Img} alt=""  style={{width:'60px',height:'auto'}} />
                </div>
            </Col>

            <Col span={4}>
                <div className="dashboard-card-shadow dashboard-card-layout">
                  <div>
                    <div style={{fontWeight:"600",fontSize:"14px"}}>叉车司机</div>
                    <div className="h3">{this.state.info.driverNum}</div>
                  </div>
                  <img src={dashboard1Img} alt=""  style={{width:'60px',height:'auto'}} />
                </div>
            </Col>
            <Col span={4}>
                <div className="dashboard-card-shadow dashboard-card-layout">
                  <div>
                    <div style={{fontWeight:"600",fontSize:"14px"}}>年检提醒</div>
                    <div className="h3">  {this.state.info.inspectedNum}</div>
                  </div>
                  <img src={dashboard5Img} alt=""  style={{width:'60px',height:'auto'}} />
                </div>
            </Col>
        </Row>

        {this.props.userInfo.companyType === '0' ? (
          <Supervise />
        ) : (
          <Supervise />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => state

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Index)
