import React from 'react';
import { withRouter } from 'react-router';
import { Route, Redirect } from 'react-router-dom';

const AuthRouter=({Component,...rest})=>{
   //Component 即需要展示的组件
   //rest 指的route所需要的参数 比如 path 等
    const isLogged=localStorage.getItem('isLogin')==="1"?true:false

    return <Route {...rest} render={props => (isLogged ? (<Component {...props} />) : <Redirect to={'/login'} />)} />
}

export default withRouter(AuthRouter)