
//首页
export default  class DashboardConstant{
    
    //访问map地图路径
    static MAPURL="http://pageapi.gpsoo.net/third?method=jump"

    //appkey "26b16d429a94539af68c143b29e054cb"   f512a6c924f6dc4d9c584c23a3bf8fb8
    static APPKEY="15a4a41eb78c9d4bbb818e6eb4eecb88"
    //账号
    static ACCOUNT="江苏华阳特安检测科技有限公司"
    //页面
    static PAGE="playback"
    //目标
    static TARGET="江苏华阳特安检测科技有限公司"

}