import axios from '@/utils/axios'

function findList(param, fun){
    axios.post('/api/sysForklift/findList', param).then((data) => {
      fun(data)
    })
}


//新增台账
function insertForklift(param, fun){
  axios.post('/api/sysForklift/insertForklift', param).then((data) => {
    fun(data)
  })
}

//批量导入新增台账
function batchInsertForklift(param, fun){
  axios.post('/api/sysForklift/batchInsertForklift', param).then((data) => {
    fun(data)
  })
}


//更新检验结论
function updateCheckResult(param, fun){
  axios.post('/api/sysForklift/updateCheckResult', param).then((data) => {
    fun(data)
  })
}

//修改叉车信息
function updateForklift(param, fun){
  axios.post('/api/sysForklift/updateForklift', param).then((data) => {
    fun(data)
  })
}





export { findList,insertForklift,batchInsertForklift,updateCheckResult,updateForklift}