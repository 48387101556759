import React, { Component } from 'react'
import { Layout, Menu} from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { setUserInfo } from '@/redux/actions/userInfo';
import { addTag } from '@/redux/actions/tagList';
import { connect } from 'react-redux';
import { menus } from '@/router/menus';
import { routes } from '@/router/routes';
// import { BorderOuterOutlined } from "@ant-design/icons"
import logo from "@/assets/images/logo.png"
//布局中左边栏
const { Sider } = Layout;
//子菜单
const { SubMenu } = Menu;

export class SideMenu extends Component {

//获取当前路径
state = { menuSelected: this.props.history.location.pathname };

//路由过滤方法
handleFilter=permission=>{


    // console.log("permission",permission)

    let index=Number.parseInt(this.props.userInfo.companyType)

     if(permission.indexOf(index)===-1){
         return false
     }else {
         return true
     }

    // return true
}
//添加点击tag
handClickTag(currentLink){
    console.log(currentLink)
    const { path, title } = currentLink; //path 路径 title 标题
    //遍历查找对应的路由组件
    for(let i=0;i<routes.length;i++){
        if (path === routes[i].path) {
            let findRoute={ path, title, component: routes[i].component} //添加路由
            this.props.addTag(findRoute); //添加路由
        }
    }
}
//渲染菜单
renderMenu(data){
  return  data.map(item=>{
      if(item.children){
        //   console.log(this.handleFilter(item.permission))
          return (
              this.handleFilter(item.permission)&&(
                  <SubMenu key={item.path}
                   title={item.title}
                   icon={item.icon}
                   >
                   {this.renderMenu(item.children)}
                  </SubMenu>
              )
          )
      }

      return (
          this.handleFilter(item.permission)&&(
           <Menu.Item key={item.path}>
               <Link to={item.path}  onClick={ ()=>this.handClickTag(item)}>
                     {item.icon?item.icon:""}
					<span>{item.title}</span>
               </Link>
           </Menu.Item>
          )
      )
  })



}

constructor(props){
    super(props)
    console.log(this.props)
}

render() {

    const menuSelected = this.props.history.location.pathname;
    const menuOpened = `/${menuSelected.split('/')[1]}`;
    const type = this.props.theme.type;
    const { collapse } = this.props;
     return (
            <Sider collapsible   collapsed={collapse.isCollapsed}  theme={type}  trigger={null} className="app-sider">
                <div className="logo" style={{color:type==="dark"?'#ffffffa6':''}}>
                     {/* {collapse.isCollapsed?<> <BorderOuterOutlined /> </>:<>叉车管理系统</> } */}
                     <img src={logo}  alt="叉车图标"  style={{wiuth:'30px',height:"15px"}}></img>
                </div>
                {/* selectedKeys={[menuSelected]} */}
				<Menu style={{ height: '50px' }} theme={type}    defaultOpenKeys={[menuOpened]} defaultSelectedKeys={[menuSelected]} selectedKeys={[menuSelected]} mode="inline">
					{this.renderMenu(menus)}
				</Menu>
            </Sider>
    )


    }
}

const mapStateToProps=state=>state

const mapDispatchToProps=dispatch=>({
    setUserInfo:data=>{
        dispatch(setUserInfo(data))
    },
    addTag:data=>{
        dispatch(addTag(data))
    }
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SideMenu))
