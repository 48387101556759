import axios from '@/utils/axios'



function register(param, fun){
    axios.post('/api/syscompany/register', param).then((data) => {
      fun(data)
    })
}



function selectAllArea(param, fun){
  axios.post('/wx/sysuser/selectAllArea', param).then((data) => {
    fun(data)
  })
}





export { register,selectAllArea }