import React, { Component } from 'react'
import 
{ 
  Skeleton,
  Form,
  Input,
  Table,
  Space,
  Button,
  Modal, message
} from 'antd';

import { SearchOutlined,ExclamationCircleOutlined} from "@ant-design/icons"


import { applyDriverCarList,checkApplyDriverCarList} from "./service"
import { connect } from 'react-redux'

const { confirm } = Modal;

export class Index extends Component {

  //form表单
  formRef = React.createRef();
  //构造器
  constructor(props){
      super(props)
      this.state={
        loading:true,
        fold:true,
        tableData:[

        ],
        pagination:{
            current:1,//当前页面
            pageSize: 10, //页面尺寸
            total:0,
            position:["bottomCenter"],
            showTotal:(total)=>`共 ${total} 条`
        },

        
      }
  }
    //	分页、排序、筛选变化时触发
    handleTableChange(pagination, filters, sorter, extra){
      
      if(extra.action==="paginate"){
         console.log("分页中")
         console.log(pagination)
         this.setState({
          pagination:{
              current:pagination.current,
              pageSize:pagination.pageSize,
              total: pagination.total,
              position:["bottomCenter"],
              showTotal:(total)=>`共 ${total} 条`
          }
         },()=>{
             console.log("修改成功")
             this.handleQueryInfo()
         })
          
          
      }

  }


   componentDidMount(){

     this.handleQueryInfo()
   }
       //表单提交
   onFormFinish(values){
       
    this.setState({
      pagination:{
          current:1,
          pageSize:10,
          total:0,
          position:["bottomCenter"],
          showTotal:(total)=>`共 ${total} 条`
      }
     },()=>{
         this.handleQueryInfo()
     })
    }
    //查询数据
    handleQueryInfo(){

      let formValues=null

      if(this.formRef.current){
          formValues=this.formRef.current.getFieldsValue()
      }
      applyDriverCarList({
          currentPage:this.state.pagination.current,
          pageSize:this.state.pagination.pageSize,
          vehicleNo:formValues?formValues.carNum:'',
          userName:formValues?formValues.userName:'',
          companyId: this.props.userInfo.id,
          companyType:this.props.userInfo.companyType
      },(res)=>{
          console.log(res)
          if(!res){
               return
          }
          this.setState({
              loading:false,
              tableData:res.data.list.map((item,index)=>{
                  item.key=index
                  return item
              }),
              pagination:{
                  current:this.state.pagination.current,
                  pageSize:this.state.pagination.pageSize,
                  total: res.data.total,
                  position:["bottomCenter"],
                  showTotal:(total)=>`共 ${total} 条`
              }
          })

      })
  }

  //审核
 handleAuth(h){

 console.log(h)


 const that=this

  confirm({
    title: '提示',
    icon: <ExclamationCircleOutlined />,
    content: '你是否确定审批该条叉车申请记录',
    okText:"同意",
    cancelText:"驳回",
    onOk() {
      return new Promise((resolve, reject) => {
        checkApplyDriverCarList({
          state:"1",
          id:h.id
        },(res)=>{
            if(res){
              message.success(res.message)
              that.handleQueryInfo()
               resolve()
            }else{
              resolve()
            }
           
        })
      })
    },
    onCancel() {
      return new Promise((resolve, reject) => {
        checkApplyDriverCarList({
          state:"2",
          id:h.id
        },(res)=>{
            if(res){
              message.success(res.message)
              that.handleQueryInfo()
               resolve()

            }else{
              resolve()
            }
           
        })
      })
    },
  });
 }

  render() {
        //form布局
        const formLayout={
          layout:'inline',
          labelCol: { span: 8,offset:0 },
          wrapperCol: { span: 16 },
      }

      const columns=[
        {
          title:'姓名',
          dataIndex:"name",
          align:"center",
        },{
          title:'车牌号',
          dataIndex:"vehicleNo",
          align:"center",          
        },
        {
          title:'点火设备名称',
          dataIndex:"equipmentName",
          align:"center",          
        },
        {
          title:'定位设备名称',
          dataIndex:"locationEquipmentName",
          align:"center",          
        },
        {
          title:'状态',
          dataIndex:"stateName",
          align:"center",          
        },
        {
          title:'操作',
          align:"center", 
          render:(h)=>{
              return (
                <Button type="primary" size="small"  onClick={()=>{this.handleAuth(h)}} >审核</Button>
              )
          }         
        }   
      ]

        return (
            <div className="shadow-radius">
               {this.state.loading&&<Skeleton  active  paragraph={{ rows: 20 }}></Skeleton>}    
               {this.state.loading||<div className="layout">
                    <Form {...formLayout}  ref={this.formRef}   onFinish={this.onFormFinish.bind(this)}>
                          <Form.Item label="姓名" name="userName"  className="form-item-margin">
                              <Input  placeholder="请输入姓名"  />
                          </Form.Item>

                          <Form.Item label="车牌号" name="vehicleNo" className="form-item-margin">
                              <Input  placeholder="请输入车牌号"  />
                          </Form.Item>  

                          <Form.Item  className="form-item-margin">
                           <Space>
                             <Button type="primary" icon={<SearchOutlined />} htmlType="submit">查询</Button>
                        
                             </Space>
                          </Form.Item>

                    </Form>

                    <Table columns={columns}   rowKey={record => record.id}  className="table-layout"  onChange={this.handleTableChange.bind(this)}  dataSource={this.state.tableData}  pagination={this.state.pagination}></Table>
                  
                  


               </div>}
            </div>
        )
    }
}



const mapStateToProps = state=>state

const mapDispatchToProps=dispatch=>({})

export default connect(mapStateToProps,mapDispatchToProps)(Index);
