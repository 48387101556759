import axios from '@/utils/axios'


function locationEquipmentList(param, fun){
  console.log(param)
    axios.post('/api/sysLocationEquipment/locationEquipmentList', param).then((data) => {
      fun(data)
    })
}








export { locationEquipmentList}