
import React, { Component } from 'react'
import { Modal,Form,Col } from 'antd';


export class ForkliftInfo extends Component {

   static defaultProps = {
      fileList:[],
      limitNum:4
    }

    state = { visible: false };

    constructor(props){
       super(props)
  
       console.log("组件详情")
    }


    showModal = () => {
      this.setState({
        visible: true,
      });
    };
  
    handleOk = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
  
    handleCancel = e => {
      console.log(e);
      this.props.close(true)
    };
    //props参数变化调用  props当前prop变化 this.props上一个props
    componentWillReceiveProps(props){
   
    }

    render() {
        return (
            <>
            <Modal
              title="叉车台账详情"
              visible={this.props.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width="800px"
              footer={null}
              maskClosable={false}
            >
          <Form  layout='inline'  labelCol={{span: 10,offset:0}}  wrapperCol={{ span: 16 }}>
             <Col span={8}>
                <Form.Item label="使用证号">
                      {this.props.detail.licenseNo}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="厂牌型号">
                  {this.props.detail.brandModel}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="车辆牌号">
                {this.props.detail.vehicleNo}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="发动机编号">
                {this.props.detail.engineNumber}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="出厂编号">
                {this.props.detail.factoryNumber}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="出厂日期">
                {this.props.detail.factoryDate}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="动力方式">
                {this.props.detail.powerWay}
                </Form.Item>
             </Col>
             <Col span={16}>
                <Form.Item label="设备地点">
                {this.props.detail.equipmentAddr}
                </Form.Item>
             </Col>

             <Col span={8}>
                <Form.Item label="制造单位">
                {this.props.detail.produceCompany}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="检验日期">
                {this.props.detail.checkoutDate}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="到期日期">
                {this.props.detail.dueDate}
                </Form.Item>
             </Col>
             <Col span={12}>
                <Form.Item label="使用单位">
                {this.props.detail.companyName}
                </Form.Item>
             </Col>
             <Col span={12}>
                <Form.Item label="使用单位地址">
                {this.props.detail.companyAddr}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="准驾司机">
                {this.props.detail.drivers}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="联系人">
                {this.props.detail.contacts}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="联系电话">
                {this.props.detail.contactsPhone}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="注册代码">
                {this.props.detail.registerCode}
                </Form.Item>
             </Col>
             {/* <Col span={8}>
                <Form.Item label="移动电话">
                   
                </Form.Item>
             </Col> */}

             {/* <Col span={12}>
                <Form.Item label="所属单位">
                  
                </Form.Item>
             </Col>
             <Col span={12}>
             <Form.Item label="联系地址">
                   
             </Form.Item>
             </Col> */}

          </Form>
            </Modal>
          </>
        )
    }
}

export default ForkliftInfo
