import React, { Component } from 'react'

export default class Error500 extends Component {
    render() {
        return (
            <div>
               500
            </div>
        )
    }
}
