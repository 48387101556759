
let baseUrl=""

if(process.env.NODE_ENV === 'development'){

    baseUrl=require("./development").baseUrl

} else if(process.env.NODE_ENV === 'production'){

    baseUrl=require("./production").baseUrl
}

export {
    baseUrl
}