
import React, { Component } from 'react'
import { Modal,Form,Col,Table,Tag  } from 'antd';
import {getMaintainDetailBy } from "./service"

export class Detail extends Component {

    state = { visible: false,tableData:[] };

    constructor(props){
       super()
       console.log("组件详情")
      //  console.log(this.props)
    }

    showModal = () => {
      this.setState({
        visible: true,
      });
    };
  
    handleOk = e => {
      console.log(e);
      this.setState({
        visible: false,
      });
    };
  
    handleCancel = e => {
      console.log(e);
      this.props.close(true)
    };
    componentDidMount(){
       console.log("哈哈")
    }
    //props参数变化调用  props当前prop变化 this.props上一个props
    componentWillReceiveProps(props){
      //  console.log(props)
      if(props.visible===true){
         console.log(props)

         getMaintainDetailBy({
            id:props.detail.id,
            maintainCycle:props.detail.maintainCycle
         },(res)=>{
            console.log(res)
            if(res){
               this.setState({
                  tableData:res.data
               })

            }
            
         })
      } 
    }

    render() {
        const columns=[
            {
                dataIndex:"maintainItem",
                title:"检查项目",
                align:"center",
            },
            {
               
                title:"检查结论",
                align:"center",
                render:(h)=>{
                   
                   if(h.result==="true"){
                       return   <Tag color="blue">正常</Tag>
                    }else if(h.result==="false"){
                       return <Tag color="red">异常</Tag>
                    }
                  
                } 

            }
        ]
     


        return (
            <>
            <Modal
              title="点检详情"
              visible={this.props.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              width="800px"
              footer={null}
              maskClosable={false}
            >
          <Form  layout='inline'  labelCol={{span: 10,offset:0}}  wrapperCol={{ span: 16 }}>
             <Col span={8}>
                <Form.Item label="车辆牌号">
                     {this.props.detail.vehicleNo}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="保养人">
                   {this.props.detail.maintainPeople}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="保养类型">
                {this.props.detail.maintainCycleName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="使用单位">
                {this.props.detail.companyName}
                </Form.Item>
             </Col>
             <Col span={8}>
                <Form.Item label="保养时间">
                {this.props.detail.maintainDate}
                </Form.Item>
             </Col>

          </Form>

               <Table style={{marginTop:"10px"}}  rowKey={record => record.maintainItem}  columns={columns} dataSource={this.state.tableData} pagination={null}></Table>
            </Modal>
          </>
        )
    }
}

export default Detail
