import React, { Component } from 'react'
import { Modal,Alert,Form,Input,Radio,Checkbox,Button,Card,message, Select} from 'antd';
import { register,selectAllArea } from "./service"
import Detail from "./Detail"

const { Option } = Select;

export class Register extends Component {

    state = { visible: false,radioValue:1,checkBox:false,dialogVisible:false,optionList:[]}

     constructor(props){
          super()
          console.log("注册界面")
     }
    
     componentDidMount(){

      selectAllArea({},(res)=>{
        if(res){
          console.log(res)

          let _optionList=res.data.map((item,index)=>{
          return <Option key={index} value={item.companyId}>{item.companyName}</Option>
          })
         this.setState({
          optionList:_optionList
         })

        }
      })

     }

         //props参数变化调用  props当前prop变化 this.props上一个props
    componentWillReceiveProps(props){
        console.log(props)
    } 
    
    handleCancel(){
        window.history.back(-1)
    }
    onFormFinish(value){
      console.log(value)
      if(this.state.checkBox===false){
        message.error("请同意《智慧叉车管理平台》并完全接受服务协议")
        return 
      }
 
      const hide =  message.loading("正在注册...",0)
      register({
        companyType:this.state.radioValue,
        companyName:value.name,
        companyCode:value.code,
        contacts:value.linkMan,
        contactsPhone:value.linkPhone,
        password:value.password1,
        companyAddr:value.address,
        parentId:value.parentId
      },(res)=>{
         hide()
          console.log(res)
          if(res){
            
             Modal.success({
                content: res.message,
                onOk:()=>{
                    window.history.back(-1)
                }
              });
          }
      })
    }
    radioOnChange(e){
      console.log('radio checked', e.target.value);
      this.setState({
        radioValue: e.target.value,
      });

    }
    //显示协议
    showProtocol(){
      console.log("哈哈")
      this.setState({
        dialogVisible:true
      })
    }

    render() {

        return (
            <>
     <Card title="【智慧叉车管理平台】企业账号注册" bordered hoverable style={{width:'60%',margin:'30px auto',minWidth:'800px'}}>
      <p>如果您已有账号，请点击此处 <span style={{'color':'#139BDC',cursor:'pointer',textDecoration:"underline"}} onClick={this.handleCancel.bind(this)}> 登录</span></p>
     {/* <p>如果您已有账号，请点击此处 <span style={{'color':'#139BDC',cursor:'pointer',textDecoration:"underline"}} onClick={this.handleCancel.bind(this)}> 登录</span>, 记不清楚密码请点击 <span style={{'color':'#F33125',cursor:'pointer',textDecoration:"underline"}}>找回密码。</span></p> */}
    <Alert message=""  description="基本信息及联系信息请如实填写，您填写的信息将受到【智慧叉车管理平台】相关隐私保护，仅用于系统相关业务和政府监管，概不外漏，请放心填写。"  type="info" showIcon />
   <Form  onFinish={this.onFormFinish.bind(this)}  style={{marginTop:'30px'}} labelCol={{span: 4,offset:0}}  wrapperCol={{ span: 9 }} labelAlign="left">
    <p  style={{fontWeight:'bold',fontSize:'17px'}}>账户信息</p>
    <Form.Item label="单位类型" >
     <Radio.Group onChange={this.radioOnChange.bind(this)} value={this.state.radioValue}>
       <Radio value={1}>单位</Radio>
       <Radio value={2}>个人</Radio>
     </Radio.Group>
     </Form.Item>
     {
       this.state.radioValue===1?<>
     <Form.Item label="企业名称" name="name" rules={[{ required: true, message: '请输入企业名称' }]} hasFeedback>
        <Input placeholder="请输入企业名称"></Input>
     </Form.Item>
     <Form.Item label="社会统一信用代码"   name="code" rules={[{ required: true, message: '请输入社会统一信用代码' }]} hasFeedback>
        <Input placeholder="请输入社会统一信用代码"></Input>
     </Form.Item>
       </>:
       <>
       <Form.Item label="使用者姓名" name="name"  rules={[{ required: true, message: '请输入使用者姓名' }]} hasFeedback>
        <Input placeholder="请输入使用者姓名"></Input>
       </Form.Item>
       <Form.Item label="身份证号" name="code"  rules={[{ required: true, message: '请输入使用者身份证号' }]} hasFeedback>
         <Input placeholder="请输入使用者身份证号"></Input>
       </Form.Item>                        
       </>
     }

 
     <Form.Item label="地址" name="address"  rules={[{ required: true, message: '请输入单位地址' }]} hasFeedback>
         <Input placeholder="请输入单位详细地址"></Input>
       </Form.Item> 

     <Form.Item label="所属区域" name="parentId"  rules={[{ required: true, message: '请输入所属区域' }]} hasFeedback>
        
        <Select placeholder="请选择所属区域">
              {this.state.optionList}
        </Select>
       </Form.Item>  



     <p  style={{fontWeight:'bold',fontSize:'17px'}}>联系人信息</p>
     <Form.Item label="联系人" name="linkMan" rules={[{ required: true, message: '请输入联系人姓名' }]}  hasFeedback>
        <Input placeholder="请输入联系人姓名"></Input>
     </Form.Item>
     <Form.Item label="联系电话"  name="linkPhone" rules={[{ required: true, message: '请输入联系电话' }]} hasFeedback>
        <Input placeholder="请输入联系电话"></Input>
     </Form.Item>
      <p  style={{fontWeight:'bold',fontSize:'17px'}}>设置密码</p>
      <Form.Item label="设置密码" name="password1"   hasFeedback rules={[{ required: true, message: '请输入设置密码' }]}>

        <Input.Password placeholder="请输入设置密码"></Input.Password>
     </Form.Item>
     <Form.Item label="确认密码"  name="password2" hasFeedback  rules={[
         {
           required: true,
           message: '请输入设置密码',
         },
         ({ getFieldValue }) => ({
           validator(rule, value) {
             if (!value || getFieldValue('password1') === value) {
               return Promise.resolve();
             }
             return Promise.reject('两次密码必须输入一致');
            
           },
         }),
       ]}>
        <Input.Password placeholder="请输入设置密码"></Input.Password>
     </Form.Item>
    
      <div style={{textAlign:'center',width:'100%'}}>

          <Checkbox onChange={(e)=>{
             this.setState({checkBox:e.target.checked})
          }}>我同意<span style={{color:'#2599F3'}} onClick={this.showProtocol.bind(this)}>《智慧叉车管理平台》</span>并完全接受服务协议</Checkbox><br/>
          <Button type="primary" htmlType="submit" style={{marginTop:'30px'}}> 注册</Button>
      </div> 

</Form>

  </Card>,
   <Detail visible={this.state.dialogVisible} close={()=>{this.setState({dialogVisible:false})}}></Detail>

          </>
        )
    }
}

export default Register
